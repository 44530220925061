import React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { format } from 'date-fns'
import Layout from '../components/layout'

export default function Blog({data}) {

  const posts = data.allMarkdownRemark.edges;

  return <Layout>

              <Helmet>
                <title>Blog</title>
                <meta name="description" content="" />
              </Helmet>

            <div className="container">

                <h1>Blog</h1>

                <div className="blog">

                {posts.map(({ node }) => (

                    <div key={node.id} className="blog__list-item">
                        <Link to={'/blog'+node.fields.slug}><img src={node.frontmatter.img.publicURL} alt="" loading="lazy" /></Link>
                        <h2><Link to={'/blog'+node.fields.slug}>{node.frontmatter.title}</Link></h2>
                        <time dateTime={node.frontmatter.date}>{format(new Date(node.frontmatter.date), 'do MMMM, yyyy')}</time>
                    </div>

                ))}

                </div>

            </div>

</Layout>
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: {frontmatter: {type: {eq: "blog"}}}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date
            img {
                publicURL
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }`